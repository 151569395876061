import * as pages from './pages';
import config from 'config';

const result = {
  [pages.clientsAndProjects]: `${config.UI_URL_PREFIX}/${pages
    .clientsAndProjects}`,
  [pages.compensationTypes]: `${config.UI_URL_PREFIX}/${pages
    .compensationTypes}`,
  [pages.commonSettings]: `${config.UI_URL_PREFIX}/${pages.commonSettings}`,
  [pages.costItems]: `${config.UI_URL_PREFIX}/${pages.costItems}`,
  [pages.taxTypes]: `${config.UI_URL_PREFIX}/${pages.taxTypes}`,
  [pages.contractors]: `${config.UI_URL_PREFIX}/${pages.contractors}`,
  [pages.currencyRates]: `${config.UI_URL_PREFIX}/${pages.currencyRates}`,
  [pages.defaultPage]: `${config.UI_URL_PREFIX}/${pages.defaultPage}`,
  [pages.expenses]: `${config.UI_URL_PREFIX}/${pages.expenses}`,
  [pages.invoices]: `${config.UI_URL_PREFIX}/${pages.invoices}`,
  [pages.login]: `${config.UI_URL_PREFIX}/${pages.login}`,
  [pages.payrolls]: `${config.UI_URL_PREFIX}/${pages.payrolls}`,
  [pages.reports]: `${config.UI_URL_PREFIX}/${pages.reports}`,
  [pages.settings]: `${config.UI_URL_PREFIX}/${pages.settings}`,
  [pages.userManagement]: `${config.UI_URL_PREFIX}/${pages.userManagement}`,
};

export default result;
